import { render, staticRenderFns } from "./KeyPoints.vue?vue&type=template&id=734c5b4f&scoped=true&"
import script from "./KeyPoints.vue?vue&type=script&lang=js&"
export * from "./KeyPoints.vue?vue&type=script&lang=js&"
import style0 from "./KeyPoints.scss?vue&type=style&index=0&id=734c5b4f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "734c5b4f",
  null
  
)

export default component.exports